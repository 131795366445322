import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "社内勉強会 TechLunch でジョブメドレーでの CircleCI の活用と改善について発表しました",
  "date": "2020-04-30T10:39:50.000Z",
  "slug": "entry/2020/04/30/193950",
  "tags": ["medley"],
  "hero": "./2020_04_30.png",
  "heroAlt": "circleci"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、メドレープロダクト開発室 エンジニアの岸田です。`}</p>
    <p>{`先日、社内勉強会 TechLunch にて、弊社の提供する医療介護分野の人材プラットフォーム「ジョブメドレー」の開発で利用している CircleCI での CI/CD についての取り組みを発表しましたので、紹介させていただきたいと思います。`}</p>
    <h1>{`ジョブメドレーの開発で CircleCI をどのように利用しているか`}</h1>
    <p>{`ジョブメドレーの開発では、主に次の 2 つを CircleCI を用いて行なっています。`}</p>
    <ul>
      <li parentName="ul">{`ユニットテスト・構文チェック`}</li>
      <li parentName="ul">{`デプロイ`}</li>
    </ul>
    <p>{`デプロイに関しては、ECS 環境と EC2 環境への 2 通りのデプロイを CircleCI を利用して行なっています。`}</p>
    <p>{`そのため CircleCI の Workflow は「ユニットテスト・構文チェック」「EC2 へのデプロイ」「ECS へのデプロイ」の 3 つに分かれています。`}</p>
    <p>{`3 つの Workflow を大まかに説明させていただきます。`}</p>
    <h2>{`ユニットテスト・構文チェック`}</h2>
    <p>{`ジョブメドレーは主にサーバサイドを Ruby on Rails、フロントエンドを React を使って開発をしています。
そのためユニットテストには RSpec ・ Jest を、構文チェックには Rubocop と ESLint 利用しています。`}</p>
    <p>{`この Workflow は 3 つのジョブで構成されています。`}</p>
    <ol>
      <li parentName="ol">{`ライブラリのインストール`}
        <ul parentName="li">
          <li parentName="ul"><code parentName="li" {...{
              "className": "language-text"
            }}>{`bundle install`}</code>{`・ `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`yarn install`}</code>{`などを行い、ユニットテスト・構文チェックの実行に必要な依存ライブラリをインストールし、CircleCI のキャッシュ機能を用いてキャッシュを行なっています`}</li>
        </ul>
      </li>
      <li parentName="ol">{`RSpec の実行`}
        <ul parentName="li">
          <li parentName="ul">{`2 コンテナを利用しての並列実行を行なっています`}</li>
        </ul>
      </li>
      <li parentName="ol">{`構文チェックと Jest の実行`}
        <ul parentName="li">
          <li parentName="ul">{`RSpec と比較して Jest でのテストコードはボリュームが少なく・実行時間が短いため、構文チェックと一緒に 1 つのジョブにしています`}</li>
        </ul>
      </li>
    </ol>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200430/20200430190805.png",
      "alt": "20200430190805.png"
    }}></img>
    <p>{`この Workflow はどのブランチでも GitHub へ Push が行われるたびに実行されるようにしています。`}</p>
    <h2>{`EC2 へのデプロイ`}</h2>
    <p>{`この Workflow ではデプロイを行うための準備と、文字通り EC2 へのデプロイを行なっています。
デプロイには Ruby gem の Capistrano を利用しています。`}</p>
    <p>{`下記のような 2 つのジョブで構成しています。`}</p>
    <ol>
      <li parentName="ol">{`ライブラリのインストール`}
        <ul parentName="li">
          <li parentName="ul">{`前述のユニットテスト・構文チェックの Workflow の 1 つ目と同じ役割です`}</li>
        </ul>
      </li>
      <li parentName="ol">{`ビルドとデプロイ`}
        <ul parentName="li">
          <li parentName="ul">{`Rails の assets:precompile や`}<code parentName="li" {...{
              "className": "language-text"
            }}>{`yarn build`}</code>{`などの処理と、Capistrano を用いたデプロイを行なっています`}</li>
        </ul>
      </li>
    </ol>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200430/20200430190826.png",
      "alt": "20200430190826.png"
    }}></img>
    <p>{`この Workflow は特定のブランチでしか実行されないようになっています。`}</p>
    <h2>{`ECS へのデプロイ`}</h2>
    <p>{`この Workflow では ECS へデプロイするための Docker image 作成とそのためのビルドなどを行なっています。`}</p>
    <p>{`ジョブは下記のように 4 つで構成されています。`}</p>
    <ol>
      <li parentName="ol">{`npm package のインストール`}</li>
      <li parentName="ol">{`フロントエンドのビルド`}</li>
      <li parentName="ol">{`Gem のインストールと Rails の assets:precompile`}</li>
      <li parentName="ol">{`Docker image の作成と Push`}</li>
    </ol>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200430/20200430190429.png",
      "alt": "20200430190429.png"
    }}></img>
    <p>{`こちらの Workflow も特定のブランチでしか実行されないようになっています。`}</p>
    <h1>{`抱えていた課題と assets:precompile`}</h1>
    <p>{`ジョブメドレーの開発では CircleCI の各ジョブが全て正常に完了することを PR をマージする条件の 1 つにしています。
しかし各 Workflow ・ジョブの実行時間が長く、ジョブの実行待ちをしなければいけないという状況がよく起こってしまっていました。`}</p>
    <p>{`特に時間がかかっていたのが、下記の 3 つでした。`}</p>
    <ol>
      <li parentName="ol">{`「EC2 へのデプロイ」Workflow の「デプロイ」ジョブ`}</li>
      <li parentName="ol">{`「ECS へのデプロイ」Workflow の「Gem のインストールと Rails の asset_precompile」ジョブ`}</li>
      <li parentName="ol">{`「RSpec の実行」ジョブ`}
        <ul parentName="li">
          <li parentName="ul">{`RSpec の書き方などを改善することで短縮できるため、割愛させていただきます`}</li>
        </ul>
      </li>
    </ol>
    <p>{`これらについて調査したところ、1・ 2 は、assets:precompile が主に時間を使っていることが分かりました。
この点について原因と行なった改善を説明をさせていただこうと思います。`}</p>
    <h2>{`assets:precompile に時間がかかる`}</h2>
    <p>{`ジョブメドレーでは Rails のアセットパイプラインを利用して、アセットファイルのコンパイル・最小化などを行なっています。
これを実行する際に `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`assets:precompile`}</code>{` コマンドを利用しています。
また、同コマンド実行時にコンパイルしたファイルを AWS S3 バケットにアップロードするために `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`asset_sync`}</code>{` gem を利用しています。`}</p>
    <p>{`このコマンドの実行には 9 分から 10 分ほどの時間がかかっており、下記の 2 つの原因で遅くなっていました。`}</p>
    <ol>
      <li parentName="ol">{`毎回 1 からコンパイルを行なっていた`}</li>
      <li parentName="ol">{`コンパイル後のファイルをアップロードする S3 バケットに大量のファイルが存在する`}</li>
    </ol>
    <h3>{`毎回 1 からコンパイルを行なっていた`}</h3>
    <p>{`こちらについては読んで字のごとくですが、デプロイ Workflow が実行されるたびにアセットファイルの変更有無に関わらず、毎回 3 分ほどを費やして全てのアセットファイルをコンパイル・最小化していました。
こちらの解決策としては、`}<a parentName="p" {...{
        "href": "https://circleci.com/docs/ja/2.0/caching/#%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5%E3%81%AE%E4%BF%9D%E5%AD%98%E5%BE%A9%E5%85%83%E3%81%AE%E5%8F%82%E8%80%83%E4%BE%8B"
      }}>{`CircleCI の公式ドキュメントでも例が載せられています`}</a>{`が、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`assets:precompile`}</code>{` コマンドで生成されるファイルが置かれるディレクトリ（`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`public/assets`}</code>{`）を CircleCI でキャッシュさせることで対応しました。
キャッシュさせることで、毎回 3 分ほどかかっていた処理を 1 分ほどに短縮することができました。`}</p>
    <h3>{`コンパイル後のファイルをアップロードする S3 バケットに大量のファイルが存在する`}</h3>
    <p>{`こちらついてはもう少し背景が複雑かつ、まだ解決まではできていません。`}</p>
    <p>{`まず `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`asset_sync`}</code>{` を利用したファイルのアップロード処理ですが、毎回 6 分以上の時間がかかっていました。
CircleCI のログをよくよく見てみるとアップロード自体に時間がかかっているのではなく、「どのファイルをアップロードすべきか」を判断する処理に多くの時間を費やしていることが分かりました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200430/20200430190719.png",
      "alt": "20200430190719.png"
    }}></img>
    <p>{`（1 ファイルもアップロードしていないが 6 分 20 秒かかっている）`}</p>
    <p>{`そこで、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`asset_sync`}</code>{` gem のソースコードを読んでみると、「アップロード先の S3 バケットにあるファイルを全て取得し、 `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`assets:precompile`}</code>{` コマンドが生成したファイルのファイル名と比較する」という処理がありました。
この処理が怪しいのではないかと思い、S3 バケットを確認してみたところ数十万件以上のファイルが存在することが分かりました。`}</p>
    <p>{`数十万件以上のファイルの情報を取得していることを考えると 6 分以上時間がかかるのも納得です。`}</p>
    <p>{`この問題の解決策は下記の 2 つが考え得ると考えました。`}</p>
    <ol>
      <li parentName="ol">{`不要なファイルを S3 バケットから削除する`}</li>
      <li parentName="ol">{`前回のデプロイとの比較をして S3 にアクセスせずにアップロードすべきファイルかどうかを判断するようにする`}</li>
    </ol>
    <p>{`1 つ目の方法は正攻法で、数十万ファイルを全て使っているわけではないため利用されていないファイルを削除してしまう方法です。`}</p>
    <p>{`asset_sync も早くなり、S3 の利用料も少なくなるためこの方法を取れるのであれば、この方法で解決するのが良いように思います。
ジョブメドレーでもこの方法を取れないかと検討しましたが、ジョブメドレーから配信している HTML メールなどでも利用しているファイルがあるため一概にアクセスされていないからといって削除することができず、この方法での解決は一旦断念しました。`}</p>
    <p>{`2 つ目の方法は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`assets:precompile`}</code>{`コマンドが生成する manifest ファイル（生成したファイルのリストなどが記述されている）と CircleCI のキャッシュ機能を使って短縮する方法です。`}</p>
    <p>{`manifest ファイルは、コンパイル後のアセットファイルが出力されるディレクトリ（`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`public/assets/`}</code>{`）に同じように出力され、また、上記で`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`public/assets`}</code>{`を CircleCI のキャッシュ機能でキャッシュするようにしたため、manifest ファイルも一緒にキャッシュされるようになっています。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`assets:precompile`}</code>{`の実行により今回作成された manifest ファイルと、キャッシュされていた manifest ファイルを比較して差分が出たファイルだけを S3 にアップロードするようにしようという試みです。
この処理は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`asset_sync`}</code>{` gem ではできそうになかったので、シェルスクリプトと Rake タスクを作成して実行してみたところ、「アップロードすべきファイルかどうか」を判断するための時間がほぼなくなり、6 分以上の短縮をすることができました。`}</p>
    <p>{`ただし検証が不十分なため、実運用に乗せることはまだできていません。`}</p>
    <h1>{`CircleCI のジョブ実行時間を短縮する小さな改善事項`}</h1>
    <p>{`上述の通り、各 Workflow で大きく時間を費やしているのは`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`assets:precompile`}</code>{`と RSpec の実行でしたが、細かな点としては他にも小さい改善をしたことがあります。`}</p>
    <h2>{`コードのチェックアウト`}</h2>
    <p>{`CircleCI では組み込みのコマンドとして `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`checkout`}</code>{` コマンドがあります。
これは対象のリポジトリのブランチをクローンしてくれるコマンドですが、ジョブメドレーはモノレポに近い構成になっており、コードベースのサイズや履歴が大きいためクローンするだけである程度の時間がかかってしまっています。`}</p>
    <p>{`そこでまずは、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.git`}</code>{`ディレクトリを CircleCI のキャッシュ機能を利用してキャッシュするようにしてみました。
すると、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`checkout`}</code>{`コマンド自体は大きく短縮しましたが、キャッシュの save/restore に短縮した以上の時間がかかるようになってしまいました。`}</p>
    <p>{`別の方法として`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`checkout`}</code>{`コマンドを利用せずに、Git の Shallow clone や Sparse clone を駆使して必要なファイルや履歴だけをクローンするということができます。
現在は Sparse clone を一部導入してみており、Shallow clone も導入したいと考えています。`}</p>
    <h2>{`Docker image の作成`}</h2>
    <p>{`ジョブメドレーでは ECS へのデプロイの際に CircleCI 上で Docker image をビルドしているため、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker build`}</code>{`コマンドの実行時間も可能な限り抑えたいと考えています。`}</p>
    <p>{`短縮する方法は様々あるかと思いますが、CircleCI 上でも Dokcer Buildkit を利用することがきますので、それを利用してビルドすることで簡単に短縮することができます。`}</p>
    <p><a parentName="p" {...{
        "href": "https://matsuand.github.io/docs.docker.jp.onthefly/develop/develop-images/build_enhancements/"
      }}>{`詳しくは Docker のガイド`}</a>{`に記載の通りではありますが、DOCKER_BUILDKIT=1 を指定して`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker build`}</code>{`を実行するだけで利用することができ、ジョブメドレーでは 2 分ほどかかっていたビルドを 40 秒ほど短縮することができました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回は、TechLunch で発表したジョブメドレーにおける CircleCI の活用と改善の取り組みについて紹介させていただきました。
今回紹介させていただいた以外にも様々な用途があり、今後もさらにうまく活用していきたいと思っています。`}</p>
    <p>{`ご覧いただきありがとうございました。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      